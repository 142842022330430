import { createSlice } from '@reduxjs/toolkit'


const authReducer = createSlice({
  name: 'auth',
  initialState: {
    auth: null,
    profile: null
  },
  reducers: {
    setAuthAction: (state, action) => {
      state.auth = action.payload;
    },
    logoutAction: (state) => {
      state.auth = null;
      state.profile = null;
    },
    setProfileAction: (state, action) => {
      state.profile = action.payload;
    }
  }
});

export default authReducer;
