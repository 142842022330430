import { useTranslation } from 'react-i18next';
import { formatVideoDuration } from '../../lib/LessonPart'


function CourseCardBlock2({ course }) {
  const { t } = useTranslation();

  return (
    <div>
      <a href={`/course/${course.slug}/`}>
        <div className="course-card">
          <div className="course-card-thumbnail ">
            <img alt="" src={course.cover_card} />
            <span className="play-button-trigger"></span>
          </div>
          <div className="course-card-body">
            <div className="course-card-info">
              <div>
                <span className="catagroy">{course.category.title}</span>
              </div>
              <div>
                <i className="icon-feather-bookmark icon-small"></i>
              </div>
            </div>

            <h4>{course.title} </h4>
            <p>{course.description}</p>

            <div className="course-card-footer">
              <h5> <i className="icon-feather-film"></i> {course.lessons_count} {t("user_view.lectures")} </h5>
              <h5> <i className="icon-feather-clock"></i> {formatVideoDuration(t, course.total_video_duration)}</h5>
            </div>
          </div>

        </div>
      </a>
    </div>
  )
}

export default CourseCardBlock2;
