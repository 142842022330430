import { Redirect } from "react-router-dom";

import { useState } from 'react';
import { useSelector } from 'react-redux'
import { authSelector } from '../store';
import { authProvider } from '../auth';
import { useTranslation } from 'react-i18next';


function LoginPage() {
  const { t } = useTranslation();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const auth = useSelector(authSelector);

  const submitLogin = (e) => {
    e.preventDefault();
    authProvider.login(username, password, (res) => {
      if (res) {
        setErrorMessage("");
      } else {
        setErrorMessage("Can not login using provided credentials!");
      }
    });
  };

  if (auth) {
    return <Redirect to="/" />;
  }

  return (
    <div className="page-content">
      <div className="page-content-inner">
        <div className="uk-width-1-3@m m-auto my-5">
          <div className="mb-4">
            <h2 className="mb-0">{t("login.welcome")}</h2>
            <p className="my-0">{t("login.description")}</p>
          </div>
          <form onSubmit={submitLogin}>
            {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
            <div className="uk-form-group">
              <label className="uk-form-label">{t("fields.username")}</label>

              <div className="uk-position-relative w-100">
                <span className="uk-form-icon">
                  <i className="icon-feather-mail"></i>
                </span>
                <input className="uk-input" id="username" type="text" placeholder="username" value={username} onChange={(e) => setUserName(e.target.value)} />
              </div>

            </div>

            <div className="uk-form-group">
              <label className="uk-form-label">{t("fields.password")}</label>

              <div className="uk-position-relative w-100">
                <span className="uk-form-icon">
                  <i className="icon-feather-lock"></i>
                </span>
                <input className="uk-input" type="password" placeholder="********" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>

            </div>

            <div className="uk-flex-middle uk-grid-small" uk-grid="uk-grid">
              <div className="uk-width-auto@s">
                <button type="submit" className="btn btn-default">{t("buttons.login")}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default LoginPage;
