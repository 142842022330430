import { configureStore, createSelector, combineReducers } from '@reduxjs/toolkit'
import { getDefaultMiddleware } from '@reduxjs/toolkit';

import authReducer from './store/auth';
import settingsReducer from './store/settings';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


const reducers = combineReducers({
  auth: authReducer.reducer,
  settings: settingsReducer.reducer,
});

export const { setAuthAction, logoutAction, setProfileAction } = authReducer.actions;
export const { setNightMode } = settingsReducer.actions;

const selectSelf = (state) => state;
export const authSelector = createSelector(selectSelf, state => state.auth.auth);
export const userSelector = createSelector(selectSelf, state => state.auth.profile);
export const nightModeSelector = createSelector(selectSelf, state => state.settings.night_mode);


const persistConfig = {
  key: 'root',
  storage
};

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  }
})


const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: customizedMiddleware
});
export const persistor = persistStore(store);

store.subscribe(() => console.log("store", store.getState()));
