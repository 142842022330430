function CookiePolicyPage() {
  return (
    <div className="page-content">
      <div className="page-content-inner">
        <div className="container-small mt-6">
          <div className="text-center my-4">
            <h1 className="uk-heading-line text-center"><span>Політика Cookie</span></h1>
            <span>Останнє оновлення 15.07.2021</span>
          </div>
          <article className="uk-article">
            <p>Файли cookie - це невеликі текстові файли, які можуть використовуватися веб сайтами для підвищення ефективності роботи відвідувача.</p>
            <p>Відповідно до законодавства ЄС ми можемо зберігати файли cookies на ваших приладах, якщо вони необхідні для діяльності вебсайту. Для усіх інших видів cookies нам потрібна ваша згода,</p>
            <p>Цей <a href="https://fourfox.com.ua/">сайт</a> використовує різні типи файлів cookie. Деякі файли cookie розміщуються сторонніми службами, які з'являються на наших сторінках. Ви можете в будь-який час змінити або відкликати свою згоду з допомогою Політики Cookie на нашому вебсайті або за допомогою налаштувань у вашому браузері.</p>
            <p>Дізнатися більше про те, хто ми такі, як ви можете з нами зв'язатися і як ми обробляємо особисті дані ви можете за допомогою нашої Політики приватності.</p>

            <h4 className="mt-4" id="who-pass">Необхідні cookies</h4>
            <p>Необхідні файли cookie допомагають зробити вебсайт придатним для використання завдяки включенню основних функцій, таких як навігація по сторінках і доступ до захищених областей вебсайту. Сайт не може нормально функціонувати без цих файлів cookie.</p>

            <table class="uk-table uk-table-striped"  >
              <thead>
                <tr>
                  <td>Назва</td>
                  <td>Призначення</td>
                  <td>Термін зберігання</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>cache-sprite-plyr</td>
                  <td>Цей файл cookie необхідний для функції кешування. Кеш використовується веб-сайтом для оптимізації часу відгуку між відвідувачем та веб-сайтом. Зазвичай кеш зберігається у браузері відвідувача.</td>
                  <td>Постійно</td>
                </tr>
                <tr>
                  <td>csrftoken</td>
                  <td>Допомагає запобігти атакам підробки міжсайтових запитів (CSRF).</td>
                  <td>1 рік</td>
                </tr>
              </tbody>
            </table>
          </article>
        </div>
      </div>
    </div>
  );
}

export default CookiePolicyPage;
