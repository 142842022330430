
import { createSlice } from '@reduxjs/toolkit'


const settingsReducer = createSlice({
  name: 'settings',
  initialState: {
    night_mode: false
  },
  reducers: {
    setNightMode: (state, action) => {
      state.night_mode = !!action.payload;
    }
  }
});

export default settingsReducer;
