import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { authSelector } from '../../store';
import { authProvider } from '../../auth';
import { useSelector } from 'react-redux'

import RecentSearchesBlock from '../../Blocks/Header/RecentSearchesBlock'


function SearchBox() {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [foundCourses, setFoundCourses] = useState([]);
  const auth = useSelector(authSelector);

  useEffect(() => {
    if (query) {
      authProvider.request(auth, "get", `/api/autocomplete?query=${query}`).then((res) => {
        let newResults = [];
        for (let row of res.data) {
          newResults.push({
            "title": row.title,
            "url": `/course/${row.slug}/`
          });
        }
        setFoundCourses(newResults);
      });
    } else {
      setFoundCourses([]);
    }
  }, [query, auth])

  const handleChangeQuery = (event) => {
    setQuery(event.target.value);
  };

  return (
    <>
      <div className="searchbox uk-visible@s">
        <input className="uk-search-input" type="search" value={query} onChange={handleChangeQuery} placeholder={t("menu.search")} />
        <button className="btn-searchbox"></button>
      </div>

      <RecentSearchesBlock results={foundCourses} />
    </>
  )
}

export default SearchBox;
