import React, { useContext, createContext } from "react";
import axios from 'axios';

import {
  Route,
  Redirect
} from "react-router-dom";
import { useSelector } from 'react-redux'
import { setAuthAction, logoutAction, setProfileAction, store, authSelector } from './store';
import i18n from './i18n';


const authContext = createContext();
const api_url = process.env.REACT_APP_API_URL || "";


export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const auth = useSelector(authSelector);
  return {
    auth
  };
}

export const authProvider = {
  login: (username, password, cb) => {
    axios.post(api_url + "/api/token/", {
      username,
      password
    })
    .then(res => {
      if (res.status === 200) {
        // setUser(res.data);
        authProvider.checkToken(res.data, function(status) {
          store.dispatch(setAuthAction(res.data));
          cb(status);
        }).catch(e => {
          cb(false);
        });
      } else {
        cb(false);
      }
    }).catch(e => {
      cb(false);
    });
  },
  logout: () => {
    // setUser(null);
    store.dispatch(logoutAction());
  },
  refresh: (auth) => {
    return axios.post(api_url + "/api/token/refresh/", {
      "refresh": auth.refresh
    }, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  },
  handleAuthError: (auth, method, url, data, headers, resolve, reject) => {
    authProvider.refresh(auth)
      .then(res => {
        let newAuth = {
          "access": res.data.access,
          "refresh": auth.refresh
        };
        store.dispatch(setAuthAction(newAuth));

        headers["Authorization"] = "Bearer " + res.data.access;

        axios({
            method: method,
            url: api_url + url,
            data: data,
            headers: headers,
          })
          .then(new_res => {
            resolve(new_res);
          })
          .catch(e => {
            if (e.response.status === 401 && e.response.data && e.response.data.detail) {
              authProvider.logout(auth);
            }
            reject(e)
          });
      }).catch(e => {
        if (e.response.status === 401 && e.response.data && e.response.data.detail) {
          authProvider.logout(auth);
        }
        reject(e);
      });
  },
  request: (auth, method, url, data, headers) => {
    return new Promise((resolve, reject) => {
      if (!headers) {
        headers = {};
      }
      if (auth) {
        headers["Authorization"] = "Bearer " + auth.access;
      }
      headers["Accept-Language"] = i18n.language;

      axios({
          method: method,
          url: api_url + url,
          data: (method.toLowerCase() === "get") ? null : data,
          params: (method.toLowerCase() === "get") ? data : null,
          headers: headers,
        })
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          if (e.response.status === 401) {
            // try refresh token
            authProvider.handleAuthError(auth, method, url, data, headers, resolve, reject);
          } else {
            reject(e);
          }
        });
    });
  },

  checkToken: (auth, callback) => {
    if (!auth) {
      if (callback) {
        callback(false);
      }
      return;
    }

    return authProvider.request(auth, "get", "/api/me/", {}, {}).then(res => {
      store.dispatch(setProfileAction(res.data));
      if (callback) {
        callback(true);
      }
    });
  }
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const state = store.getState()
const auth = state.auth ? state.auth.auth : null;
if (auth) {
  authProvider.checkToken(auth);
}
