import React, { Suspense } from 'react';
import { Provider } from 'react-redux'

import './App.css';

import { ProvideAuth } from './auth';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react'
import Layout from './Blocks/Layout';


function App() {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ProvideAuth>
          <Suspense fallback="loading">
            <Layout />
          </Suspense>
        </ProvideAuth>
      </PersistGate>
    </Provider>
  );
}

export default App;
