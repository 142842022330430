import React, { Suspense, useState, useEffect } from 'react';
import { useSelector } from 'react-redux'

import CourseCardBlock from '../Blocks/Course/CourseCardBlock';
import Pagination from '../Blocks/Pagination';
import { authSelector } from '../store';
import { authProvider } from '../auth';
import { useTranslation } from 'react-i18next';

import { Intent, Spinner } from "@blueprintjs/core";


function HomePage() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [courses, setCourses] = useState(null);
  const [level, setLevel] = useState(null);

  const { t } = useTranslation();
  const auth = useSelector(authSelector);

  useEffect(() => {
    let data = {};
    if (level) {
      data["level"] = level;
    }
    authProvider.request(auth, "get", "/api/courses/", data).then((res) => {
      setCourses(res.data);
      setIsLoaded(true);
    });
  }, [auth, level])

  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  }

  return (
    <div className="page-content">
      <div className="page-content-inner">
        <div className="mt-lg-5" uk-grid="uk-grid">
          <div className="uk-width-1-4@m">

            <div className="sidebar-filter" uk-sticky="top:20 ;offset: 90; bottom: true ; media : @m">

              <button className="btn-sidebar-filter" uk-toggle="target: .sidebar-filter; cls: sidebar-filter-visible">{t("buttons.filter")}</button>

              <div className="sidebar-filter-contents">
                <h4>{t("home_view.filter_by")} </h4>
                <ul className="sidebar-filter-list" uk-accordion="multiple: true">
                  <li className="uk-open">
                    <a className="uk-accordion-title" href="/">{t("home_view.skill_levels")}</a>
                    <div className="uk-accordion-content">
                      <div className="uk-form-controls">
                        <label>
                          <input className="uk-radio" type="radio" value="BE" name="radio1" checked={level === "BE"} onChange={handleLevelChange} />
                          <span className="test">{t("level.BE")}</span>
                        </label>
                        <label>
                          <input className="uk-radio" type="radio" value="IN" name="radio1" checked={level === "IN"} onChange={handleLevelChange} />
                          <span className="test">{t("level.IN")}</span>
                        </label>
                        <label>
                          <input className="uk-radio" type="radio" value="AD" name="radio1" checked={level === "AD"} onChange={handleLevelChange} />
                          <span className="test">{t("level.AD")}</span>
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="section-header mb-lg-3">
              <div className="section-header-left">
                <h4>{t("home_view.all")}</h4>
              </div>
              <div className="section-header-right">
              </div>
            </div>
            { isLoaded ?
              <Suspense fallback="loading">
                {courses.results.map((course, i) =>
                  <CourseCardBlock key={"course" + i} course={course} />
                )}
                <Pagination pagination={courses.pagination} />
              </Suspense>
            :
              <Spinner intent={Intent.PRIMARY} />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
