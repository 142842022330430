import { useTranslation } from 'react-i18next';
import { calculateVideoDuration, calculateQuizItems } from '../../lib/LessonPart';


function CourseLessonPartBlock({ course, lesson, lesson_part }) {
  const { t } = useTranslation();

  let extra = "";
  if (lesson_part.category === "VIDEO") {
    const durationSeconds = calculateVideoDuration(lesson_part);
    extra = <span className="time">{Math.floor(durationSeconds / 60)} {t("time.minutes")}</span>
  }
  if (lesson_part.category === "QUIZ") {
    const countItems = calculateQuizItems(lesson_part);
    extra = <span className="time">{countItems}</span>
  }

  return (
    <li>
      <a href={`/course/${course.slug}/${lesson.id}/${lesson_part.id}/`}>{lesson_part.title}</a>
      {lesson_part.passed && <span className="watched">{t("course_view.watched")}</span>}
      {extra && extra}
    </li>
  )
}

export default CourseLessonPartBlock;
