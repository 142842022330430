import React from 'react';


function FeedbackPage() {
  return (
    <div className="page-content">
      <div className="page-content-inner">
        <div className="mt-lg-5" uk-grid="uk-grid">
          <div className="feedback">
            <iframe
              title="Feedback"
              id="ytplayer1"
              type="text/html"
              maxWidth="640"
              maxHeight="360"
              width="80%"
              height="300"
              src="https://www.youtube.com/embed/MI89S9RV6JM?autoplay=0"
              frameborder="0"
            ></iframe>
            <iframe
              title="Feedback"
              id="ytplayer2"
              type="text/html"
              maxWidth="640"
              maxHeight="360"
              width="80%"
              height="300"
              src="https://www.youtube.com/embed/F_zTjU6lhwM?autoplay=0"
              frameborder="0"
            ></iframe>
            <iframe
              title="Feedback"
              id="ytplayer3"
              type="text/html"
              maxWidth="640"
              maxHeight="360"
              width="80%"
              height="300"
              src="https://www.youtube.com/embed/UswrRdKl3Uc?autoplay=0"
              frameborder="0"
            ></iframe>
            <iframe
              title="Feedback"
              id="ytplayer4"
              type="text/html"
              maxWidth="640"
              maxHeight="360"
              width="80%"
              height="300"
              src="https://www.youtube.com/embed/SoyqPASwCws?autoplay=0"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeedbackPage;
