const _ = require('lodash');


function Pagination({ pagination, onChangePage }) {
  if (pagination.count === 1) {
    return null;
  }

  const handleChangePage = (e, page) => {
    e.preventDefault();
    onChangePage(page);
  };

  return (
    <ul className="uk-pagination my-5 uk-flex-center" uk-margin="uk-margin">
      { pagination.number > 1 &&
        <li>
          <a onClick={(e) => handleChangePage(e, 1)} href="?page=1">
            <span uk-pagination-previous="uk-pagination-previous"></span>
          </a>
        </li>
      }
      {_.range(1, pagination.num_pages + 1).map((num) =>
        <li className={num === pagination.number ? "uk-active" : ""} key={"page-" + num}>
          <a href={`?page=${num}`} onClick={(e) => handleChangePage(e, num)}>{num}</a>
        </li>
      )}
      { pagination.number < pagination.num_pages &&
        <li>
          <a onClick={(e) => handleChangePage(e, pagination.num_pages)} href={"?page=" + pagination.num_pages}>
            <span uk-pagination-next="uk-pagination-next"></span>
          </a>
        </li>
      }
    </ul>
  )
}

export default Pagination;
