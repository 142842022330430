import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import VideoBlock from '../../Blocks/VideoBlock';
import QuizBlock from '../Quiz/QuizBlock';

import { useSelector } from 'react-redux'
import { authSelector } from '../../store';
import { authProvider } from '../../auth';

const _ = require('lodash');


function LessonPartContent({ course, lesson, lesson_part, opUpdateLessonPart }) {
  const auth = useSelector(authSelector);
  const history = useHistory();
  const [answers, setAnswers] = useState({});
  const [wrongAnswers, setWrongAnswers] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();

  const onView = () => {
    authProvider.request(auth, "post", `/api/courses/${course.slug}/lessons/${lesson.id}/lesson_parts/${lesson_part.id}/complete/`).then((res) => {
      if (opUpdateLessonPart) {
        // opUpdateLessonPart(res.data);
      }
    });
  };

  const handleNext = (event) => {
    function redirect(resNext) {
      if (resNext.data) {
        const lesson_part = resNext.data;
        history.push(`/course/${course.slug}/${lesson_part.lesson_id}/${lesson_part.id}/`);
      } else {
        history.push(`/course/${course.slug}/`);
      }
    }

    event.preventDefault();
      authProvider.request(auth, "post", `/api/courses/${course.slug}/lessons/${lesson.id}/lesson_parts/${lesson_part.id}/next/`).then((resNext) => {
        if (auth) {
          authProvider.request(auth, "post", `/api/courses/${course.slug}/lessons/${lesson.id}/lesson_parts/${lesson_part.id}/complete/`).then((resComplete) => {
            redirect(resNext);
          });
        } else {
          redirect(resNext);
        }
    });
  };

  const handleCheck = (event) => {
    event.preventDefault();
    authProvider.request(auth, "post", `/api/courses/${course.slug}/lessons/${lesson.id}/lesson_parts/${lesson_part.id}/check/`, answers).then((res) => {
      if (res.data.is_passed) {
        if (res.data.next_lesson_part) {
          const lesson_part = res.data.next_lesson_part;
          history.push(`/course/${course.slug}/${lesson_part.lesson_id}/${lesson_part.id}/`);
        } else {
          history.push(`/course/${course.slug}/`);
        }
      } else {
        setWrongAnswers(res.data.wrong_answers);
        setCorrectAnswers(res.data.correct_answers);
        setErrorMessage(t("lesson_part_view.try_fail"));
        setAnswers({});
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    });
  };

  const handleChangeAnswer = (quiz_item_id, answer) => {
    let new_answers = _.cloneDeep(answers);
    new_answers[quiz_item_id] = {
      "answer": answer
    };
    setAnswers(new_answers);
  };

  if (lesson_part.category === "VIDEO") {
    return (
      <div>
        <div className="video-responsive">
          <VideoBlock onView={onView} video={lesson_part.videos[0]}/>
        </div>

        <div style={{
          margin: '20px',
          textAlign: 'center',
        }}>
          <button onClick={handleNext} type="button" className="btn btn-primary bg-soft-success">{t("buttons.next")}</button>
        </div>

        <div>
          <p dangerouslySetInnerHTML={{ __html: lesson_part.description }}></p>
        </div>
      </div>
    )
  }

  if (lesson_part.category === "QUIZ") {
    return (
      <div>
        {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}

        <QuizBlock onChangeAnswer={handleChangeAnswer} answers={answers} quiz={lesson_part.quizzes[0]} wrongAnswers={wrongAnswers} correctAnswers={correctAnswers} />

        {auth ?
        <button onClick={handleCheck} type="button" className="btn btn-primary bg-soft-success">{t("buttons.check")}</button>
        :
        <a className="btn btn-primary bg-soft-success" href="/login">Please login</a>
        }
      </div>
    )
  }
}

export default LessonPartContent;
