import { useTranslation } from 'react-i18next';
import { authSelector } from '../store';
import { useSelector } from 'react-redux'

import SocialButtons from '../Components/SocialButtons';


function FooterBlock() {
  const { t, i18n } = useTranslation();
  let date = new Date();

  const auth = useSelector(authSelector);

  const setLanguage = (e) => {
    e.preventDefault();
    i18n.changeLanguage(e.target.dataset.lang);
    window.location.reload();
  };

  return (
    <div className="footer">
      <div className="container">
        <div uk-grid="uk-grid">
          <div className="uk-width-1-3@m">
            <a href="/" className="uk-logo">
              <i className="uil-graduation-hat"></i>Fourfox
            </a>
            <p className="footer-description">©</p>
          </div>
          <div className="uk-width-expand@s uk-width-1-2">
            <div className="footer-links pl-lg-8">
              <h5>Explore </h5>
              <ul>
                <li><a href="/"> Courses </a></li>
                <li><a href="course-path.html"> Track </a></li>
              </ul>
            </div>
          </div>
          {
            auth ?
            <div className="uk-width-expand@s uk-width-1-2">
              <div className="footer-links pl-lg-8">
                <h5>{t("footer.account")}</h5>
                <ul>
                  <li><a href="profile-1.html">{t("footer.profile")}</a></li>
                  <li><a href="/">{t("footer.settings")}</a></li>
                </ul>
              </div>
            </div>
            :
            null
          }
          <div className="uk-width-expand@s uk-width-1-2">
            <div className="footer-links pl-lg-8">
              <h5>Resources</h5>
              <ul>
                <li><a href="/contact">Contact</a></li>
                <li><a href="/feedback">Feedback</a></li>
                <li><a href="/privacy">Privacy Policy</a></li>
                <li><a href="/cookie-policy">Cookie policy</a></li>
              </ul>
            </div>
          </div>
          <div className="uk-width-expand@s uk-width-1-2">
            <div className="footer-links pl-lg-8">
              <h5>{t("footer.language")}</h5>
              <ul>
                <li><a href="/" data-lang="en" onClick={setLanguage}>English</a></li>
                <li><a href="/" data-lang="uk" onClick={setLanguage}>Ukranian</a></li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div className="uk-grid-collapse" uk-grid="uk-grid">
          <div className="uk-width-expand@s uk-first-column">
            <p>© {date.getFullYear()} <strong>Fourfox</strong>. {t("rights_reserved")}. </p>
          </div>
          <div className="uk-width-auto@s">
            <SocialButtons />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterBlock;
