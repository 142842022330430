function AccountPage() {
  return (
    <div className="page-content">
      <div className="page-content-inner">
        <h2>Account page</h2>
      </div>
    </div>
  )
}

export default AccountPage;
