import UserPanel from './UserPanel';
import AnonymousUserPanel from './AnonymousUserPanel';
import { userSelector } from '../../store';
import { useSelector } from 'react-redux'


function HeaderRightBlock() {
  const user = useSelector(userSelector);

  return (
    <div className="uk-navbar-right">
      { user ? <UserPanel user={user} /> : <AnonymousUserPanel /> }

      <a className="uk-navbar-toggle uk-hidden@s"
        uk-toggle="target: .nav-overlay; animation: uk-animation-fade" href="/">
        <i className="uil-search icon-small"></i>
      </a>

      <a href="/" className="uil-user icon-small uk-hidden@s"
        uk-toggle="target: .header-widget ; cls: is-active">
      </a>

    </div>
  )
}

export default HeaderRightBlock;
