import { useState, useMemo } from 'react';
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Intent, Spinner } from "@blueprintjs/core";

import { authSelector, userSelector } from '../store';
import { authProvider } from '../auth';

import { useEffect } from 'react';

import LessonBlock from '../Blocks/LessonPart/LessonBlock';
import LessonPartContent from '../Blocks/LessonPart/LessonPartContent';
import NightModeButton from '../Components/NightModeButton';
import { nightModeSelector } from '../store';

import MyMenu from '../Blocks/Header/MyMenu';


const _ = require('lodash');

function LessonPartPage() {
  let { id, lp_id } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [course, setCourse] = useState(null);
  const nightMode = useSelector(nightModeSelector);

  const user = useSelector(userSelector);
  const auth = useSelector(authSelector);
  const { t } = useTranslation();

  useEffect(() => {
    authProvider.request(auth, "get", `/api/courses/${id}/`).then((res) => {
      setCourse(res.data);
      setIsLoaded(true);
    });
  }, [auth, id, lp_id]);

  const [ current_lesson, current_lesson_part ] = useMemo(() => {
    let current_lesson_part, current_lesson;
    if (course) {
      for (let lesson of course.lessons) {
        for (let lesson_part of lesson.lesson_parts) {
          if (lesson_part.id === parseInt(lp_id)) {
            current_lesson = _.cloneDeep(lesson);
            current_lesson_part = _.cloneDeep(lesson_part);
          }
        }
      }
    }
    return [current_lesson, current_lesson_part];
  }, [course, lp_id]);

  const handleUpdateLessonPart = (new_lesson_part) => {
    let new_course = _.cloneDeep(course);

    for (let lesson of course.lessons) {
      if (lesson.id === new_lesson_part.lesson_id) {
        for (let i in lesson.lesson_parts) {
          if (lesson.lesson_parts[i].id === new_lesson_part.id) {
            lesson.lesson_parts[i] = new_lesson_part;
            break;
          }
        }
      }
    }
    setCourse(new_course);
  };

  return (
    <div id="wrapper" className={nightMode ? "night-mode" : ""}>
      {isLoaded ?
        <div className="course-layouts">
          <div className="course-content bg-dark">
            <div className="course-header">
              <a href="#none" className="btn-back" uk-toggle="target: .course-layouts; cls: course-sidebar-collapse">
                <i className="icon-feather-chevron-left"></i>
              </a>

              <h4 className="text-white">{current_lesson_part.title}</h4>
              <div>
                <a href="#none">
                  <i className="icon-feather-help-circle btns"></i>
                </a>
                <div uk-drop="pos: bottom-right;mode : click">
                  <div className="uk-card-default p-4">
                    <h4>{course.title}</h4>
                    <p className="mt-2 mb-0">{course.description}</p>
                  </div>
                </div>

                <a href="#none">
                  <i className="icon-feather-more-vertical btns"></i>
                </a>

                { user ?
                  <MyMenu />
                  :
                  <div className="dropdown-option-nav " uk-dropdown="pos: bottom-right ;mode : click">
                    <ul>
                      <li>
                        <a href={`/course/${course.slug}/`}>Course details</a>
                      </li>
                      <li>
                        <NightModeButton />
                      </li>
                    </ul>
                  </div>
                }
              </div>
            </div>

            <div className="course-content-inner">
              <LessonPartContent opUpdateLessonPart={handleUpdateLessonPart} course={course} lesson={current_lesson} lesson_part={current_lesson_part} />
            </div>
          </div>

          <div className="course-sidebar">
            <div className="course-sidebar-title">
              <h3>{t("lesson_part_view.table_of_contents")}</h3>
            </div>
            <div className="course-sidebar-container" data-simplebar="data-simplebar">
              <ul className="course-video-list-section" uk-accordion="uk-accordion">
                {course.lessons.map((lesson, i) =>
                  <LessonBlock key={"lesson" + i} course={course} lesson={lesson} />
                )}
              </ul>
            </div>
          </div>
        </div>
        :
        <Spinner intent={Intent.PRIMARY} />
      }
    </div>
  );
}

export default LessonPartPage;
