import { useState } from 'react';
import PropTypes from 'prop-types';

function RatingBlock({ rating, show_value, can_edit, onChange }) {
  const [hover, setHover] = useState(0);
  let numbers = [1, 2, 3, 4, 5];

  const getClass = (num) => {
    let parts = ["star"];
    let diff = rating - num + 1;
    if (diff < 0.25) {
      parts.push("empty")
    } else if (diff >= 0.25 && diff <= 0.75) {
      parts.push("half");
    }
    if (hover && (hover >= num)) {
      parts.push("hover");
    }
    return parts.join(" ");
  };

  const toggleHoverOn = (event) => {
    if (can_edit) {
      setHover(event.target.dataset.num);
    }
  };

  const toggleHoverOff = (event) => {
    if (can_edit) {
      setHover(0);
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    console.log(hover);
    if (can_edit && onChange) {
      onChange(hover);
    }
  };

  return (
    <div className="star-rating">
      {show_value && <span className="avg">{rating}</span>}
      {numbers.map((num, i) =>
        <span key={"rating-" + i}
          data-num={num}
          onClick={handleClick}
          onMouseEnter={toggleHoverOn}
          onMouseLeave={toggleHoverOff}
          className={getClass(num)}></span>
      )}
    </div>
  )
}


RatingBlock.propTypes = {
  rating: PropTypes.any.isRequired,
  show_value: PropTypes.bool
};

RatingBlock.defaultProps = {
  show_value: true,
  can_edit: false,
};

export default RatingBlock;
