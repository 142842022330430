import { useTranslation } from 'react-i18next';


function RecentSearchesBlock({ results }) {
  const { t } = useTranslation();

  return (
    <div style={{display: results.length ? "block" : "none" }} uk-dropdown="pos: top;mode:click;animation: uk-animation-slide-bottom-small" className="dropdown-search">
      <div className="erh BR9 MIw" style={{ top: '-27px', position: 'absolute', left: '24px', fill: 'currentColor', height: '24px', pointerEvents: 'none', color: '#f5f5f5' }}>
        <svg width="22" height="22"><path d="M0 24 L12 12 L24 24"></path></svg>
      </div>

      <ul className="dropdown-search-list">
        <li className="list-title">{t("menu.search")}</li>
        {results.map((res, n) =>
          <li key={`search-result-${n}`}><a href={res.url}>{res.title}</a>
        </li>
        )}
      </ul>
    </div>
  )
}

export default RecentSearchesBlock;
