import { useParams } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import { calculateVideoDuration, calculateQuizItems } from '../../lib/LessonPart';
import { classNames } from '../../utils';


function LessonPartBlock({ course, lesson, lesson_part }) {
  let { le_id, lp_id } = useParams();
  const { t } = useTranslation();

  let extra = "";
  if (lesson_part.category === "VIDEO") {
    const durationSeconds = calculateVideoDuration(lesson_part);
    extra = <span>{Math.floor(durationSeconds / 60)} {t("time.minutes")}</span>
  }
  if (lesson_part.category === "QUIZ") {
    const countItems = calculateQuizItems(lesson_part);
    extra = <span>{countItems}</span>
  }

  const uk_active = (lesson.id === parseInt(le_id)) && (lesson_part.id === parseInt(lp_id));
  let classes = {
    "watched": !!lesson_part.passed || uk_active,
    "uk-active": uk_active
  };

  return (
    <li className={classNames(classes)}>
      <a href={`/course/${course.slug}/${lesson.id}/${lesson_part.id}/`}>
        {lesson_part.title} {extra}
      </a>
    </li>
  )
}

export default LessonPartBlock;
