function SocialButtons() {
  return (
    <nav className="footer-nav-icon">
      <ul>
        <li><a href="/"><i className="icon-brand-facebook"></i></a></li>
        <li><a href="/"><i className="icon-brand-dribbble"></i></a></li>
        <li><a href="https://www.youtube.com/@fourfox_edu" target="_blank" rel="noreferrer"><i className="icon-brand-youtube"></i></a></li>
        <li><a href="/"><i className="icon-brand-twitter"></i></a></li>
      </ul>
    </nav>
  )
}

export default SocialButtons;
