import LessonPartBlock from '../../Blocks/LessonPart/LessonPartBlock';
import { useTranslation } from 'react-i18next';


function LessonBlock({ course, lesson }) {
  const { t } = useTranslation();

  return (
    <li className="uk-open">
      <a className="uk-accordion-title" href="#none">{t("course_view.topic")} {lesson.order}. {lesson.title}</a>
      <div className="uk-accordion-content">
        <ul className="course-video-list highlight-watched">
          {lesson.lesson_parts.map((lp, j) =>
            <LessonPartBlock key={"lesson-part" + j} course={course} lesson={lesson} lesson_part={lp} />
          )}
        </ul>
      </div>
    </li>
  );
}

export default LessonBlock;
