function Page404() {
  return (
    <div className="page-content">
      <div className="page-content-inner">
        <div className="container-small mt-6">
          <div className="text-center my-4">
            <h1 className="uk-heading-line text-center"><span>404 Not Found</span></h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page404;
