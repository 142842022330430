import MyCourses from './MyCourses';
import MyMenu from './MyMenu';
import SearchBox from '../../Blocks/Header/SearchBox';
import UserAvatar from '../../Components/UserAvatar';


function UserPanel({ user }) {

  return (
    <div className="header-widget">
      <SearchBox />

      <span className="icon-feather-x icon-small uk-hidden@s"
        uk-toggle="target: .header-widget ; cls: is-active"> </span>

      <a href="#none" className="header-widget-icon"
        uk-tooltip="title: My Courses ; pos: bottom ;offset:21">
        <i className="uil-youtube-alt"></i>
      </a>

      <MyCourses user={user} />

      <a href="/" className="header-widget-icon profile-icon">
        <UserAvatar user={user} className="header-profile-icon" />
      </a>

      <MyMenu />
    </div>
  )
}

export default UserPanel;
