import { useState } from 'react';
import { Redirect } from "react-router-dom";

import { authSelector } from '../store';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { authProvider } from '../auth';
import { classNames } from '../utils';


const _ = require('lodash');


function RegisterPage() {
  const auth = useSelector(authSelector);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errors, setErrors] = useState(null);
  const { t } = useTranslation();

  if (auth) {
    return <Redirect to="/" />;
  }

  const handleRegister = (e) => {
    e.preventDefault();
    if (password !== password2) {
      setErrors({
        "password": t("errors.password2"),
        "password2": t("errors.password2"),
      });
      return
    }
    authProvider.request(auth, "post", "/api/register/", {
      username, email, firstName, lastName, password
    }).then((res) => {
      setUsername("");
      setEmail("");
      setFirstName("");
      setLastName("");
      setPassword("");
      setPassword2("");
      setErrorMessage("");
      setSuccessMessage(t("register.success"));
      setErrors(null);
    }).catch(e => {
      setErrorMessage(t("register.error"));
      setSuccessMessage("");
      setErrors(e.response.data);
    });
  };

  return (
    <div className="page-content">
      <div className="page-content-inner">
        <div className="uk-width-1-3@m m-auto my-5">
          <div className="mb-4">
            <h2 className="mb-0">{t("register.welcome")}</h2>
            <p className="my-0">{t("register.description")}</p>
          </div>
          {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
          {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
          {successMessage ?
            <a href="/login">{t("buttons.login")}</a>
            :
            <form className={classNames({
              "uk-child-width-1-1": true, "uk-grid-small": true, "uk-grid": true, "needs-validation": true
            })} uk-grid="uk-grid" onSubmit={handleRegister}>

              <div className="uk-first-column">
                <div className="uk-form-group">
                  <label className="uk-form-label">{t("fields.username")}</label>

                  <div className="uk-position-relative w-100">
                    <input
                      className={classNames({ "uk-input": true, "form-control": true, "is-invalid": !!_.get(errors, ["username"]) })}
                      required="required"
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      name="username"
                      placeholder={t("fields.username")} />
                    <div className="invalid-feedback">{_.get(errors, ["username"])}</div>
                  </div>
                </div>
              </div>

              <div className="uk-grid-margin uk-first-column">
                <div className="uk-form-group">
                  <label className="uk-form-label">{t("fields.email")}</label>

                  <div className="uk-position-relative w-100">
                    <input
                      className={classNames({ "uk-input": true, "form-control": true, "is-invalid": !!_.get(errors, ["email"]) })}
                      required="required"
                      type="email"
                      value={email}
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="name@example.com" />
                    <div className="invalid-feedback">{_.get(errors, ["email"])}</div>
                  </div>

                </div>
              </div>

              <div className="uk-width-1-2@s uk-grid-margin uk-first-column">
                <div className="uk-form-group">
                  <label className="uk-form-label">{t("fields.first_name")}</label>

                  <div className="uk-position-relative w-100">
                    <input
                      className={classNames({ "uk-input": true, "form-control": true, "is-invalid": !!_.get(errors, ["first_name"]) })}
                      type="text"
                      value={firstName}
                      name="firstName"
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder={t("fields.first_name")} />
                    <div className="invalid-feedback">{_.get(errors, ["first_name"])}</div>
                  </div>
                </div>
              </div>

              <div className="uk-width-1-2@s uk-grid-margin">
                <div className="uk-form-group">
                  <label className="uk-form-label">{t("fields.last_name")}</label>

                  <div className="uk-position-relative w-100">
                    <input
                      className={classNames({ "uk-input": true, "form-control": true, "is-invalid": !!_.get(errors, ["last_name"]) })}
                      type="text"
                      value={lastName}
                      name="lastName"
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder={t("fields.last_name")} />
                    <div className="invalid-feedback">{_.get(errors, ["last_name"])}</div>
                  </div>

                </div>
              </div>

              <div className="uk-width-1-2@s uk-grid-margin uk-first-column">
                <div className="uk-form-group">
                  <label className="uk-form-label">{t("fields.password")}</label>

                  <div className="uk-position-relative w-100">
                    <input
                      className={classNames({ "uk-input": true, "form-control": true, "is-invalid": !!_.get(errors, ["password"]) })}
                      required="required"
                      type="password"
                      value={password}
                      name="password"
                      onChange={(e) => setPassword(e.target.value)} placeholder="********" />
                    <div className="invalid-feedback">{_.get(errors, ["password"])}</div>
                  </div>

                </div>
              </div>
              <div className="uk-width-1-2@s uk-grid-margin">
                <div className="uk-form-group">
                  <label className="uk-form-label">{t("fields.password2")}</label>

                  <div className="uk-position-relative w-100">
                    <input
                      className={classNames({ "uk-input": true, "form-control": true, "is-invalid": !!_.get(errors, ["password2"]) })}
                      required="required"
                      type="password"
                      value={password2}
                      name="password2"
                      onChange={(e) => setPassword2(e.target.value)} placeholder="********" />
                    <div className="invalid-feedback">{_.get(errors, ["password2"])}</div>
                  </div>

                </div>
              </div>

              <div className="uk-grid-margin uk-first-column">
                <div className="mt-4 uk-flex-middle uk-grid-small uk-grid" uk-grid="uk-grid">
                  <div className="uk-width-auto@s">
                    <input type="submit" className="btn btn-default" value={t("buttons.register")} />
                  </div>
                </div>
              </div>

            </form>
          }
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
