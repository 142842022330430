import { useTranslation } from 'react-i18next';


function MyCourses({ user }) {
  const { t } = useTranslation();

  return (
    <div uk-dropdown="pos: top;mode:click;animation: uk-animation-slide-bottom-small" className="dropdown-notifications my-courses-dropdown">
      <div className="dropdown-notifications-headline">
        <h4>Your Courses</h4>
        <a href="#none">
          <i className="icon-feather-settings" uk-tooltip="title: Notifications settings ; pos: left"></i>
        </a>
      </div>

      <div className="dropdown-notifications-content" data-simplebar="data-simplebar">
        <ul>
          {user.courses.map((course, i) =>
            <li className="notifications-not-read" key={"my-courses" + i}>
              <a href={`/course/${course.slug}/`}>
                <span className="notification-image">
                  <img src={course.cover_card} alt="" /> </span>
                <span className="notification-text">
                  <span className="course-title">{course.title}</span>
                  <span className="course-number">6/35 </span>
                  <span className="course-progressbar">
                    <span className="course-progressbar-filler" style={{ width: '95%' }}></span>
                  </span>
                </span>

                <span className="btn-option">
                  <i className="icon-feather-more-vertical"></i>
                </span>
                <div className="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                  <ul>
                    <li>
                      <span>
                        <i className="icon-material-outline-dashboard"></i>
                        Course Dashboard
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="icon-feather-video"></i>
                        Resume Course
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="icon-feather-x"></i>
                        Remove Course
                      </span>
                    </li>
                  </ul>
                </div>
              </a>
            </li>
          )}
        </ul>

      </div>
      <div className="dropdown-notifications-footer">
        <a href="/">{t("buttons.see_all")}</a>
      </div>
    </div>
  )
}

export default MyCourses;
