function PrivacyPage() {
  return (
    <div className="page-content">
      <div className="page-content-inner">
        <div className="container-small mt-6">
          <div className="text-center my-4">
            <h1 className="uk-heading-line text-center"><span>Політика приватності «FourFox»</span></h1>
            <span>Останнє оновлення 15.07.2021</span>
          </div>
          <article className="uk-article">
            <p>Освітня Платформа «FourFox» (далі по тексту “Платформа”, “ми”, “нас”) турбується про захист ваших персональних даних і збирає їх в мінімально-необхідній кількості. Ця Політика приватності допоможе відповісти на питання, пов’язані з обробкою ваших персональних даних і тому ми просимо ознайомитись з нею.</p>
            <p>Політика приватності містить відповіді на наступні питання:</p>
            <p>
              <ul>
                <li><a href="/privacy#who">Хто володілець ваших персональних даних?</a></li>
                <li><a href="/privacy#influence">На що поширюється політика приватності?</a></li>
                <li><a href="/privacy#what-need">Які ваші дані нам потрібні та для чого?</a></li>
                <li><a href="/privacy#who-pass">Кому ми можемо передати ваші дані та для чого?</a></li>
                <li><a href="/privacy#protect">Як ми захищаємо та де зберігаємо ваші дані?</a></li>
                <li><a href="/privacy#rights">Які ви маєте права?</a></li>
                <li><a href="/privacy#refresh">Як ми оновлюємо цю Політику?</a></li>
              </ul>
            </p>

            <h4 className="mt-4" id="who">Хто володілець ваших персональних даних?</h4>
            <p>Володільцем персональних даних є ФОП Помещенко Антон Федорович, зареєстрований за адресою: Україна, 93400, Луганська обл., місто Сєвєродонецьк, вул. Маяковського, 13, кв. 56. Володілець персональних даних визначає які дані, з якою метою і як будуть оброблятися. Зв’язатись з ним можливо за допомогою пошти ferumflex@gmail.com.</p>

            <h4 className="mt-4" id="influence">На що поширюється політика приватності?</h4>
            <p>Наша політика приватності поширюється на всі дані, зібрані через сайт освітньої платформи «FourFox».</p>

            <h4 className="mt-4" id="what-need">Які ваші дані нам потрібні та для чого?</h4>
            <p>Ми збираємо та обробляємо ваші дані за допомогою Платформи діляться на дві категорії:</p>
            <p><b>Технічна інформація.</b> Коли ви заходите на наш сайт, деякі дані збираються автоматично. Нам потрібні технічні дані для роботи, підтримки і поліпшення нашого вебсайту. До таких даних, зокрема, належать IP адреса, геолокація, тип пристрою і тип браузера, файли cookie і дані про вашу взаємодію з сайтом - сесійний ID.</p>
            <p>До сесійного ID належать: ваша взаємодія з сайтом, назва сайту, з якого ви перейшли на наш сайт, використовувані вами функції, використані сторінки на нашому сайті, спосіб використання нашого сайту і дії, які ви вживаєте, якщо такі дії присутні.</p>
            <p><b>Дані, надані користувачем.</b> Для зручності, список персональних даних, які ви можете нам надати, визначені в цій таблиці:</p>

            <table class="uk-table uk-table-striped"  >
              <thead>
                <tr>
                  <td>Для чого</td>
                  <td>Перелік персональних даних</td>
                  <td>Термін зберігання</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{textAlign: "center", fontSize: "16px", fontWeight: "bold"}} colspan="3">Учні курсу</td>
                </tr>
                <tr>
                  <td>Для надання наших послуг</td>
                  <td>Прізвище, Ім’я та по-батькові учня вік (чи є 18 років)<br />Прізвище, ім’я та по-батькові когось з батьків або опікуна<br />Телефон когось з батьків<br />сума оплати</td>
                  <td>Дані зберігаються до моменту припинення надання послуг клієнту + 3 роки</td>
                </tr>
                <tr>
                  <td>Для комунікації та пошуку результатів ЗНО</td>
                  <td>Прізвище, Ім’я та по-батькові учня<br />email<br />телефон учня</td>
                  <td>Дані зберігаються до моменту припинення надання послуг клієнту + 3 роки</td>
                </tr>
                <tr>
                  <td>Для статистики та маркетингу</td>
                  <td>результати здачі ЗНО<br />вік (чи є 18 років)</td>
                  <td>Дані зберігаються до моменту припинення надання послуг клієнту і до 10 років в анонімізовану вигляді</td>
                </tr>
                <tr>
                  <td style={{textAlign: "center", fontSize: "16px", fontWeight: "bold"}} colspan="3">Репетитори</td>
                </tr>
                <tr>
                  <td>Для співпраці з нами та комунікації</td>
                  <td>Прізвище, Ім’я та по-батькові<br />email<br />телефон<br />диплом про вищу профільну освіту<br />фото</td>
                  <td>Дані зберігаються до моменту припинення надання послуг + 3 роки</td>
                </tr>
                <tr>
                  <td>Для оплати</td>
                  <td>Фінансові дані</td>
                  <td>Дані зберігаються до моменту припинення надання послуг</td>
                </tr>
                <tr>
                  <td>Для статистики та маркетингу</td>
                  <td>Кількість учнів на Платформі<br />Середній бал здачі ЗНО учнів</td>
                  <td>Дані зберігаються до моменту припинення надання послуг</td>
                </tr>
                <tr>
                  <td style={{textAlign: "center", fontSize: "16px", fontWeight: "bold"}} colspan="3">Усі відвідувачі сайту</td>
                </tr>
                <tr>
                  <td>Для коректної роботи сайту через сервіс “google analytics” збираються Cookie, але наш сайт цю інформацію не зберігає та не використовує.</td>
                  <td>Дані, запитувані сервісом “Google Analytics”і на які ви дали згоду</td>
                  <td>Визначається Політикою <a href="https://policies.google.com/privacy?hl=uk#infocollect">Google</a></td>
                </tr>
              </tbody>
            </table>
            <p>
              Звертаємо вашу увагу, що для обробки даних користувачів, які не досягли 18 років, ми <b>запитаємо підтвердження їх батьків чи опікунів</b>. Якщо нам стане відомо, що ми зібрали особисту інформацію в дитини без підтвердження згоди батьків, ми негайно видалимо цю інформацію. Якщо ви є батьком або законним опікуном та заперечуєте проти обробки персональних даних вашої дитини, будь ласка, зв'яжіться з володільцем персональних даних за електронною адресою, вказаною на початку цієї Політики приватності.
            </p>
            <p>Якщо ви бажаєте відключити cookie, то можете знайти інструкції з управління  налаштуваннями вашого браузера за цими посиланнями:</p>
            <p>
              <ul>
                <li><a href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Internet Explorer</a></li>
                <li><a href="https://help.vivaldi.com/desktop/privacy/privacy-settings/">Vivaldi</a></li>
                <li><a href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">Microsoft Edge </a></li>
                <li><a href="https://support.google.com/chrome/answer/95647">Chrome</a></li>
                <li><a href="https://operaru.ru/faq/cookies-management-in-opera">Opera</a></li>
                <li><a href="https://support.apple.com/ru-ru/guide/safari/sfri11471/mac">Safari</a></li>
                <li><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">Firefox</a></li>
              </ul>
            </p>

            <h4 className="mt-4" id="who-pass">Кому ми можемо передати ваші дані та для чого?</h4>
            <p>	Ми можемо передати деякі дані нашим партнерам, співробітникам або підрядникам, які будуть викладати, робити маркетингову розсилку, налаштовувати таргетовану рекламу та виконувати інші функції, необхідні виключно для роботи Платформи.</p>
            <p>Ми працюємо з платіжною системою Liqpay. Ця система забезпечує роботу нашої Платформи. При цьому, ми не отримуємо доступ до вашої фінансової інформації, але отримуємо інформацію про внесену суму.</p>
            <p>Крім того, ми можемо розкрити ваші персональні дані у необхідному обсязі для дотримання вимог законодавства (наприклад, за наявності постанови суду або для запобіганню шахрайству).</p>
            <p>Також ми передаємо ваші дані з наступних підстав:</p>
            <p><span style={{textDecoration: "underline"}}>Згода</span>.  Ми передаємо ваші персональні дані на основі вашої явної згоди.</p>
            <p><span style={{textDecoration: "underline"}}>Виконання норм законодавства</span>. Ми будемо розкривати ваші персональні дані третім особам в тому обсязі, в якому це необхідно:</p>
            <ul>
              <li>для виконання державного запиту, постанови суду або відповідного законодавства;</li>
              <li>для запобігання незаконному використанню нашого вебсайту;</li>
              <li>для захисту від претензій третіх сторін;</li>
              <li>для допомоги в запобіганні або розслідуванні шахрайства.</li>
            </ul>
          </article>

          <h4 className="mt-4" id="protect">Як ми захищаємо та де зберігаємо ваші дані?</h4>
          <p>Ми турбуємось про ваші дані і зберігаємо їх на сервері в Німеччині, який недоступний зовнішнім користувачам та в бухгалтерських документах. Крім того, ми використовуємо для захисту ваших даних шифрування та NDA. Дані, необхідні для статистики зберігаються в анонімізованому вигляді і, частково, у вигляді графіків. Фінансові дані репетиторів зазначаються в договорі та зберігаються в ньому та бухгалтерських документах, інші фінансові дані зберігаються лише в платіжних системах банків.</p>

          <h4 className="mt-4" id="rights">Які ви маєте права?</h4>
          <p>Якщо ви хочете отримати <b>доступ, виправити, оновити або видалити</b> ваші особисті дані, ви можете зробити це в будь-який час.</p>

          <p>Ви можете <b>заперечити проти обробки, обмежити обробку</b> ваших персональних даних або <b>перенести</b> їх.</p>
          <p>Ви маєте право <b>відмовитись від маркетингових повідомлень</b>, які ми надсилаємо вам у будь-який час. Якщо ви більше не хочете отримувати нашу розсилку новин та рекламні повідомлення, ви можете відмовитись від їх отримання, натиснувши посилання "скасувати підписку" або "відмовитись" у повідомленні, яке ми вам надсилаємо. Однак зауважте, що відмовитись від певних комунікацій, пов’язаних із послугами, може бути неможливо. Ви можете повідомити нас у будь-який час, якщо не бажаєте отримувати маркетингові повідомлення, зв’язавшись з володільцем персональних даних.</p>
          <p>Аналогічним чином, якщо ми збирали та обробляємо ваші персональні дані з вашої згоди, ви можете будь-коли <b>відкликати свою згоду</b>. Відкликання вашої згоди не вплине на обробку вашої особистої інформації, яка проводиться з будь-яких інших законних підстав обробки.</p>

          <p>Щоб скористатися своїми правами надішліть нам електронного листа на електронну пошту, вказану на <a href="https://docs.google.com/document/d/169-9wWJNHPFy2ZQYvSqCYClGBuv5hTgSE1hrUUCYdbc/edit?ts=604dd806#heading=h.7fjrm7j1pd6q">початку</a> цієї Політики приватності</p>

          <p>Якщо ваш запит не був задоволений, ви можете подати скаргу до регулюючого органу - Уповноваженого Верховної Ради України з прав людини на електронну пошту <a href="mailto:hotline@ombudsman.gov.ua">hotline@ombudsman.gov.ua</a>.</p>

          <h4 className="mt-4" id="refresh">Як ми оновлюємо цю Політику?</h4>
          <p>Ми оновлюємо Політику у випадках:</p>
          <ul>
            <li>зміни діючого законодавства, яке регулює захист персональних даних;</li>
            <li>зміни обсягу даних, які ми збираємо на наших сайтах;</li>
            <li>зміни мети обробки.</li>
          </ul>

          <p>В будь-якому випадку ми опублікуємо нову версію Політики приватності у себе на сайті.</p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPage;
