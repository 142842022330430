import { useTranslation } from 'react-i18next';
import SearchBox from '../../Blocks/Header/SearchBox'


function AnonymousUserPanel() {
  const { t } = useTranslation();

  return (
    <div className="header-widget">
      <SearchBox />

      <a href="/login" className="header-widget-icon">{t("buttons.login")}</a>

      <a href="/register" className="header-widget-icon">{t("buttons.register")}</a>
    </div>
  )
}

export default AnonymousUserPanel;
