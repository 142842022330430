import { useTranslation } from 'react-i18next';
import CourseLessonPartBlock from '../../Blocks/Course/CourseLessonPartBlock';


function CourseLessonBlock({ lesson, course }) {
  const { t } = useTranslation();

  return (
    <li className="uk-open">
      <a className="uk-accordion-title" href="/">{t("course_view.topic")} {lesson.order}. {lesson.title} </a>
      <div className="uk-accordion-content">
        <ul className="course-curriculum-list">
          {lesson.lesson_parts.map((lesson_part, i) =>
            <CourseLessonPartBlock key={"course-lesson-part-" + i} course={course} lesson={lesson} lesson_part={lesson_part} />
          )}
        </ul>
      </div>
    </li>
  )
}

export default CourseLessonBlock;
