import HeaderRightBlock from '../Blocks/Header/HeaderRightBlock';
import HeaderLeftBlock from '../Blocks/Header/HeaderLeftBlock';

function HeaderBlock() {
  return (
    <header className="header header-horizontal bg-grey uk-light">
      <div className="container">
        <nav uk-navbar="true">
          <HeaderLeftBlock />
          <HeaderRightBlock />
        </nav>
      </div>
    </header>
  )
}

export default HeaderBlock;
