import _ from 'lodash';
import QuizItemBlock from '../../Blocks/Quiz/QuizItemBlock';


function QuizBlock({ quiz, answers, onChangeAnswer, wrongAnswers, correctAnswers }) {
  return (
    <div className="course-faq uk-accordion text-white">
      {quiz.items.map((item, i) =>
        <QuizItemBlock
          number={i + 1}
          onChangeAnswer={onChangeAnswer}
          answer={_.get(answers, [item.id, "answer"])} key={"quiz-item" + i}
          item={item}
          isWrong={_.indexOf(wrongAnswers, item.id) >= 0}
          isCorrect={_.indexOf(correctAnswers, item.id) >= 0}
          />
      )}
    </div>
  );
}

export default QuizBlock;
