import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Intent, Spinner } from "@blueprintjs/core";
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

import CourseReviewBlock from '../Blocks/Course/CourseReviewBlock';
import RatingBlock from '../Blocks/RatingBlock';
import VideoBlock from '../Blocks/VideoBlock';
import CourseLessonBlock from '../Blocks/Course/CourseLessonBlock';
import { formatVideoDuration } from '../lib/LessonPart';

import { authSelector } from '../store';
import { authProvider } from '../auth';

const { DateTime } = require("luxon");


function CourseView() {
  let { id } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [course, setCourse] = useState(null);
  const [currentTab, setCurrentTab] = useState(window.location.hash ? window.location.hash.slice(1) : null);

  const auth = useSelector(authSelector);
  const { t } = useTranslation();

  useEffect(() => {
    authProvider.request(auth, "get", `/api/courses/${id}/`).then((res) => {
      setCourse(res.data);
      setIsLoaded(true);
    });
  }, [auth, id]);

  const setTab = (tab) => {
    setCurrentTab(tab);
    window.location.hash = tab;
  };

  const enrollCourse = (e) => {
    e.preventDefault();
    authProvider.request(auth, "post", `/api/courses/${id}/enroll/`).then((res) => {
      setCourse(res.data);
    });
  };

  const unenrollCourse = (e) => {
    e.preventDefault();
    authProvider.request(auth, "post", `/api/courses/${id}/unenroll/`).then((res) => {
      setCourse(res.data);
    });
  };

  const handleRate = (rate) => {
    authProvider.request(auth, "post", `/api/courses/${id}/rate/`, {"rate": rate}).then((res) => {
      setCourse(res.data);
    });
  };

  return (
    <div className="page-content">
      { isLoaded ?
        <div>
          <div className="course-details-wrapper topic-1 uk-light">
            <div className="container p-sm-0">
              <div uk-grid="uk-grid">
                <div className="uk-width-2-3@m">

                  <div className="course-details">
                    <h1>{course.title}</h1>
                    <p>{course.description}</p>

                    <div className="course-details-info mt-4">
                      <ul>
                        <li>
                          <RatingBlock rating={course.rating} can_edit={course.can_rate} onChange={handleRate} />
                        </li>
                        <li>
                          <i className="icon-feather-users" /> {course.enrolment_count} {t("buttons.enrolled")}
                        </li>
                      </ul>
                    </div>

                    <div className="course-details-info">
                      <ul>
                        <li>{t("course_view.created_by")} <a href={`/user/${course.author.id}`}>{course.author.full_name || course.author.username}</a> </li>
                        <li>{t("course_view.update_at", { date: DateTime.fromISO(course.create_date).toLocaleString() })}</li>
                      </ul>
                      <ul>
                        <li>{t("course_view.duration")}: <span class="time">{formatVideoDuration(t, course.total_video_duration)}</span></li>
                      </ul>
                    </div>
                  </div>
                  <nav className="responsive-tab style-5">
                    <ul
                      uk-switcher="connect: #course-intro-tab ;animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium">
                      <li className={currentTab === "curriculum" ? "uk-active" : ""}><a href="#curriculum" onClick={(e) => setTab("curriculum")}>{t("course_view.curriculum")}</a></li>
                      <li className={currentTab === "overview" ? "uk-active" : ""}><a href="#overview" onClick={(e) => setTab("overview")}>{t("course_view.overview")}</a></li>
                      <li className={currentTab === "reviews" ? "uk-active" : ""}><a href="#reviews" onClick={(e) => setTab("reviews")}>{t("course_view.reviews") + "(" + course.review_count + ")"}</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="uk-grid-large mt-4" uk-grid="uk-grid">
              <div className="uk-width-2-3@m">
                <ul id="course-intro-tab" className="uk-switcher mt-4">
                  <li>
                    <ul className="course-curriculum" uk-accordion="multiple: true">
                      {course.lessons.map((lesson, i) =>
                        <CourseLessonBlock key={"course-lesson-" + i} lesson={lesson} course={course} />
                      )}
                    </ul>
                  </li>

                  <li className="course-description-content">
                    <p dangerouslySetInnerHTML={{ __html: course.content }}></p>
                  </li>

                  <li>
                    <CourseReviewBlock course={course} />
                  </li>
                </ul>
              </div>

              <div className="uk-width-1-3@m">
                <div className="course-card-trailer" uk-sticky="top: 10 ;offset:105 ; media: @m ; bottom:true">
                  {course.trailers.length &&
                    <div>
                      <div className="course-thumbnail">
                        <img src={course.cover_video} alt="" />
                        <a className="play-button-trigger show" href="#trailer-modal" uk-toggle="target: #trailer-modal"> </a>
                      </div>

                      <div id="trailer-modal" uk-modal="uk-modal">
                        <div className="uk-modal-dialog">
                          <button className="uk-modal-close-default mt-2 mr-1" type="button" uk-close="uk-close"></button>
                          <div className="uk-modal-body">
                            <VideoBlock video={course.trailers[0]} />
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  <div className="p-3">
                    <div className="uk-child-width-1-1 uk-grid-small mb-4" uk-grid="uk-grid">
                      <div>
                        {course.enrollment_status ?
                          <a href="/" onClick={unenrollCourse} className="uk-width-1-1 btn btn-default transition-3d-hover">
                            <i className="uil-pause"></i>{t("buttons.unenroll")}
                          </a>
                        :
                          <a href="/" onClick={enrollCourse} className="uk-width-1-1 btn btn-default transition-3d-hover">
                            <i className="uil-play"></i>{t("buttons.enroll")}
                          </a>
                        }
                      </div>
                    </div>

                    {course.includes_text_list.length &&
                      <div>
                        <p className="uk-text-bold">{t("course_view.include")}</p>

                        <div className="uk-child-width-1-2 uk-grid-small" uk-grid="uk-grid">
                          {course.includes_text_list.map((item, i) =>
                            <div key={"course-include-" + i}>
                              <span><i className="uil-arrow-right"></i>{item}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <Spinner intent={Intent.PRIMARY} />
      }
    </div>
  );
}

export default CourseView;
