import { useState, useEffect } from 'react';
import { Intent, Spinner } from "@blueprintjs/core";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'

import { authSelector } from '../store';
import { authProvider } from '../auth';
import  UserAvatar from '../Components/UserAvatar';
import CourseCardBlock2 from '../Blocks/Course/CourseCardBlock2'


function UserView() {
  let { id } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState(null);

  const auth = useSelector(authSelector);
  const { t } = useTranslation();

  useEffect(() => {
    authProvider.request(auth, "get", `/api/user/${id}/`).then((res) => {
      setUser(res.data);
      setIsLoaded(true);
    });
  }, [auth, id]);

  return (
    <div className="page-content">
      { isLoaded ?
        <div>
          <div className="profile-container">
            <div className="page-content-inner uk-light pb-0">
              <div className="profile-layout">
                <div className="profile-layout-avature">
                  <div className="user-profile-photo">
                    <UserAvatar user={user} />
                  </div>
                </div>
                <div className="profile-layout-content">
                  <h2>{user.full_name || user.username}</h2>
                  <p>{t("user_types." + user.profile.user_type.toLowerCase())}</p>
                </div>
                <div className="profile-layout-badge">
                  <div>
                    <div className="profile-layout-badge-num">{user.courses.length}</div>
                    <div className="profile-layout-badge-title">{t("user_view.courses")}</div>
                  </div>
                    {/*
                    <div>
                      <div className="profile-layout-badge-num"> 650</div>
                      <div className="profile-layout-badge-title"> Badge</div>
                    </div>
                    */}
                </div>
              </div>
              <nav className="responsive-tab style-4">
                <ul>
                  <li className="uk-active"><a href={`/view/{user.id}/`}>{t("user_view.courses")}</a></li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="page-content-inner">
            <div className="section-small">
              <h4 className="mb-lg-5">{t("user_view.enrolled")}</h4>
              <div className="uk-child-width-1-4@m uk-child-width-1-3@s course-card-grid" uk-grid="uk-grid">
                {user.courses.map((course, i) =>
                  <CourseCardBlock2 course={course} key={`course_view_${i}`} />
                )}
              </div>
            </div>
          </div>
        </div>
        :
        <Spinner intent={Intent.PRIMARY} />
      }
    </div>
  )
}

export default UserView;
