import { useState } from "react";
import { authProvider } from "../auth";
import { useSelector } from 'react-redux'
import { authSelector } from '../store';
import { useTranslation } from 'react-i18next';


function ContactPage() {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const auth = useSelector(authSelector);

  const handleSend = (e) => {
    e.preventDefault();
    authProvider.request(auth, "post", "/api/contacts/", {
      name, email, subject, message
    }, {}).then(response => {
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
      setErrorMessage("");
      setSuccessMessage(t("contact.success"));
    }).catch(err => {
      setSuccessMessage("");
      setErrorMessage(t("contact.error"));
    })
  }

  return (
    <div className="page-content">
      <div className="page-content-inner">
        <div className="uk-width-1-3@m m-auto my-5">
          {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
          {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}

          <h1 className="mb-0">Let's Talk?</h1>
          <p className="mt-2">We like to create things with fun, open0minded people</p>

          <form uk-grid="uk-grid" className="uk-grid" onSubmit={handleSend}>
            <div className="uk-width-1-2@s uk-first-column">
              <label className="uk-form-label">Name</label>
              <input className="uk-input" required="required" type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
            </div>
            <div className="uk-width-1-2@s">
              <label className="uk-form-label">Email</label>
              <input className="uk-input" type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
            </div>
            <div className="uk-width-1-1@s">
              <label className="uk-form-label">Subject</label>
              <input className="uk-input" required="required" type="text" value={subject} onChange={(e) => setSubject(e.target.value)} placeholder="Subject" />
            </div>
            <div className="uk-width-1-1@s uk-grid-margin uk-first-column">
              <label className="uk-form-label">Message</label>
              <textarea
                required="required"
                className="uk-textarea"
                placeholder="Enter Your Message her..."
                onChange={(e) => setMessage(e.target.value)}
                style={{ height: "160px" }} value={message} />
            </div>
            <div className="uk-grid-margin uk-first-column">
              <input type="submit" value="Send" className="button success" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
