import { Radio, RadioGroup, Checkbox } from "@blueprintjs/core";
import { classNames } from '../../utils';
import { getLetter } from '../../lib/LessonPart';


const _ = require('lodash');


function QuizItemBlock({ number, item, answer, onChangeAnswer, isWrong, isCorrect }) {
  const handleChange = (event) => {
    let var_id = parseInt(event.target.value);
    onChangeAnswer(item.id, var_id);
  };

  const handleChangeMulti = (event) => {
    answer = answer || [];
    let var_id = parseInt(event.target.value);

    answer = _.remove(answer, function(n) {
      if (n.toString() === var_id.toString()) {
        return false;
      }
      return true;
    })
    if (event.target.checked) {
      answer.push(var_id);
    }
    onChangeAnswer(item.id, answer);
  };

  const handleChangeText = (event) => {
    answer = answer || new Array(parseInt(event.target.dataset.total));
    answer[event.target.dataset.number] = event.target.value;
    onChangeAnswer(item.id, answer);
  }

  const handleChangeTable = (event) => {
    answer = answer || new Array(parseInt(event.target.dataset.total));
    answer[event.target.dataset.number] = event.target.value;
    onChangeAnswer(item.id, answer);
  }

  if (item.question_type === "SIMPLE") {
    return (
      <div className="quizItem">
        <div>
          <span className={classNames({wrongAnswer: isWrong, correctAnswer: isCorrect})}>{number}.</span> <p dangerouslySetInnerHTML={{ __html: item.question }}></p>
        </div>
        <RadioGroup selectedValue={answer} onChange={handleChange} >
          {item.question_data["choices"].map((choice, j) =>
            <Radio label={choice} value={j} key={"quiz-item-choice" + j} />
          )}
        </RadioGroup>
      </div>
    )
  } else if (item.question_type === "MULTI_SIMPLE") {
      return (
        <div className="quizItem">
          <div>
            <span className={classNames({wrongAnswer: isWrong, correctAnswer: isCorrect})}>{number}.</span> <p dangerouslySetInnerHTML={{ __html: item.question }}></p>
          </div>
          <div>
            {item.question_data["choices"].map((choice, j) =>
              <Checkbox checked={_.indexOf(answer, j) >= 0} label={choice} value={j} onChange={handleChangeMulti} key={"quiz-item-choice" + j} />
            )}
          </div>
        </div>
      )
  } else if (item.question_type === "TEXT") {
    var parts = item.question_data["text"].split(/(\[place\])/gi);
    let total_number = 0;
    for (var j = 1; j < parts.length; j += 1) {
      if (parts[j] === "[place]") {
        total_number += 1;
      }
    }

    let current = 0;
    for (var i = 1; i < parts.length; i += 1) {
      if (parts[i] === "[place]") {
        parts[i] = <input key={current} data-number={current} data-total={total_number} type="text" onChange={handleChangeText} value={_.get(answer, [current], "")} style={{ display: "inline", width: "auto", height: "auto", lineHeight: "25px" }} />;
        current += 1;
      }
    }

    return (
      <div className="quizItem">
        <div>
          <span className={classNames({wrongAnswer: isWrong, correctAnswer: isCorrect})}>{number}.</span> <p dangerouslySetInnerHTML={{ __html: item.question }}></p>
        </div>
        <div>
          {parts}
        </div>
      </div>
    )
  } else if (item.question_type === "TABLE") {
    return (
      <div className="quizItem">
        <div>
          <span className={classNames({wrongAnswer: isWrong, correctAnswer: isCorrect})}>{number}.</span> <p dangerouslySetInnerHTML={{ __html: item.question }}></p>
        </div>
        <div>
          <table class="uk-table">
            <thead>
              <tr>
                <td></td>
                {[...Array(item.question_data["cols"])].map((x, i) =>
                  <td>{getLetter(i)}</td>
                )}
              </tr>
            </thead>
            <tbody>
              {[...Array(item.question_data["rows"])].map((x, j) =>
                <tr>
                  <td>{j + 1}</td>
                  {[...Array(item.question_data["cols"])].map((x, i) =>
                    <td>
                      <input type="radio" checked={_.get(answer, j, "").toString() === i.toString()} onChange={handleChangeTable} data-number={j} data-total={item.question_data["rows"]} value={i} name={"quiz-item-choice" + j} />
                    </td>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default QuizItemBlock;
