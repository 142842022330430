import logo from '../../res/logo.svg';
import { useTranslation } from 'react-i18next';


function HeaderLeftBlock() {
  const { t } = useTranslation();

  return (
    <div className="uk-navbar-left">
      <span className="mmenu-trigger">
        <button className="hamburger hamburger--collapse" type="button">
          <span className="hamburger-box">
            <span className="hamburger-inner"> </span>
          </span>
        </button>
      </span>

      <a href="/" className="logo">
        <img src={logo} alt="" />
        <span>Fourfox</span>
      </a>

      <nav id="navigation">
        <ul id="responsive">
          <li><a href="/">{t("menu.dashboard")}</a> </li>
        </ul>
      </nav>
    </div>
  )
}

export default HeaderLeftBlock;
