export function calculateVideoDuration(lesson_part) {
  let durationSeconds = 0;
  if (lesson_part.category === "VIDEO") {
    for (let video of lesson_part.videos) {
      durationSeconds += video.duration;
    }
  }

  return durationSeconds;
}

export function formatVideoDuration(t, seconds) {
  let all_minutes = parseInt(seconds / 60);
  let hours = parseInt(all_minutes / 60);
  let minutes = all_minutes % 60;
  let time_parts = {
    "hours": hours,
    "minutes": minutes
  }
  let parts = [];
  for (let p of ["hours", "minutes"]) {
    if (time_parts[p]) {
      parts.push(`${time_parts[p]} ${t("time." + p)}`)
    }
  }
  return parts.join(" ");
}

export function calculateQuizItems(lesson_part) {
  let total = 0;
  if (lesson_part.category === "QUIZ") {
    for (let quiz of lesson_part.quizzes) {
      total += quiz.items.length;
    }
  }

  return total;
}

export function getLetter(num) {
  // const letters = [
  //   "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"
  // ];
  const letters = [
    "А", "Б", "В", "Г", "Д", "Е", "Ж", "З", "К", "Л", "М"
  ];

  return letters[num];
}
