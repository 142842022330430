import { useState, useEffect } from 'react';
import { Intent, Spinner } from "@blueprintjs/core";
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

import { authSelector, userSelector } from '../../store';
import { authProvider } from '../../auth';

import RatingBlock from '../../Blocks/RatingBlock';
import Pagination from '../../Blocks/Pagination';
import UserAvatar from '../../Components/UserAvatar';


function CourseReviewBlock({ course }) {
  const [comment, setComment] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [reviews, setReviews] = useState(null);
  const auth = useSelector(authSelector);
  const user = useSelector(userSelector);
  const { t } = useTranslation();

  const loadReviews = (auth, course, page) => {
    authProvider.request(auth, "get", `/api/courses/${course.slug}/reviews/?page=${page}`).then((res) => {
      setReviews(res.data);
      setIsLoaded(true);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { comment };
    setIsSubmit(true);
    authProvider.request(auth, "post", `/api/courses/${course.slug}/reviews/`, data).then((res) => {
      setComment("");
      loadReviews(auth, course, 1);
    }).finally(() => {
      setIsSubmit(false);
    });
  };

  const onChangePage = (page) => {
    loadReviews(auth, course, page);
  };

  useEffect(() => {
    loadReviews(auth, course, 1);
  }, [auth, course]);

  return (
    <div>
      { isLoaded ?
        <div>
          <div className="review-summary">
            <h4 className="review-summary-title">{t("feedback.title")}</h4>
            <div className="review-summary-container">
              <div className="review-summary-avg">
                <div className="avg-number">{course.rating}</div>
                <div className="review-star">
                  <RatingBlock rating={course.rating} show_value={false} />
                </div>
                <span>{t("feedback.rating")}</span>
              </div>

              <div className="review-summary-rating">
                <div className="review-summary-rating-wrap">
                  <div className="review-bars">
                    <div className="full_bar">
                      <div className="bar_filler" style={{ width: course.rating_stats[5] + "%" }}></div>
                    </div>
                  </div>
                  <div className="review-stars">
                    <RatingBlock rating={5} show_value={false} />
                  </div>
                  <div className="review-avgs">{course.rating_stats[5]} %</div>
                </div>
                <div className="review-summary-rating-wrap">
                  <div className="review-bars">
                    <div className="full_bar">
                      <div className="bar_filler" style={{ width: course.rating_stats[4] + "%" }}></div>
                    </div>
                  </div>
                  <div className="review-stars">
                    <RatingBlock rating={4} show_value={false} />
                  </div>
                  <div className="review-avgs">{course.rating_stats[4]} %</div>
                </div>
                <div className="review-summary-rating-wrap">
                  <div className="review-bars">
                    <div className="full_bar">
                      <div className="bar_filler" style={{ width: course.rating_stats[3] + "%" }}></div>
                    </div>
                  </div>
                  <div className="review-stars">
                    <RatingBlock rating={3} show_value={false} />
                  </div>
                  <div className="review-avgs">{course.rating_stats[3]} %</div>
                </div>
                <div className="review-summary-rating-wrap">
                  <div className="review-bars">
                    <div className="full_bar">
                      <div className="bar_filler" style={{ width: course.rating_stats[2] + "%" }}></div>
                    </div>
                  </div>
                  <div className="review-stars">
                    <RatingBlock rating={2} show_value={false} />
                  </div>
                  <div className="review-avgs">{course.rating_stats[2]} %</div>
                </div>
                <div className="review-summary-rating-wrap">
                  <div className="review-bars">
                    <div className="full_bar">
                      <div className="bar_filler" style={{ width: course.rating_stats[1] + "%" }}></div>
                    </div>
                  </div>
                  <div className="review-stars">
                    <RatingBlock rating={1} show_value={false} />
                  </div>
                  <div className="review-avgs">{course.rating_stats[1]} %</div>
                </div>
              </div>
            </div>
          </div>

          <div className="comments">
            <h4>{t("feedback.reviews")} <span className="comments-amount"> ({reviews.pagination.count}) </span> </h4>

            <ul>
              {reviews.results.map((review, i) =>
                <li key={"course-review-" + i}>
                  <div className="comments-avatar">
                    <UserAvatar user={review.user} />
                  </div>
                  <div className="comment-content">
                    <div className="comment-by">
                      {review.user.full_name || review.user.username}
                      <span>{t("user_types." + review.user.profile.user_type.toLowerCase())}</span>
                    </div>
                    <p>{review.comment}</p>
                  </div>
                </li>
              )}
            </ul>
          </div>

          <Pagination pagination={reviews.pagination} onChangePage={onChangePage} />

          {user &&
            <div className="comments">
              <h3>{t("feedback.submit")}</h3>
              <ul>
                <li>
                  <div className="comments-avatar"><UserAvatar user={user} /></div>
                  <div className="comment-content">
                    <form className="uk-grid-small" uk-grid="uk-grid" onSubmit={handleSubmit}>
                      <div className="uk-width-1-1@s">
                        <label className="uk-form-label">{t("fields.comment")}</label>
                        <textarea className="uk-textarea"
                          placeholder={t("feedback.placeholder")}
                          style={{ height: "160px" }} onChange={(e) => setComment(e.target.value)} value={comment} />
                      </div>
                      <div className="uk-grid-margin">
                        {isSubmit ?
                          <span>
                            <input type="submit" disabled="disabled" value={t("buttons.submit")} className="btn btn-default" />
                            <Spinner intent={Intent.PRIMARY} />
                          </span>
                          :
                          <span>
                            {comment ?
                              <input type="submit" value={t("buttons.submit")} className="btn btn-default" />
                              :
                              <input type="submit" disabled="disabled" value={t("buttons.submit")} className="btn btn-default" />
                            }
                          </span>
                        }
                      </div>
                    </form>
                  </div>
                </li>
              </ul>
            </div>
          }
        </div>
        :
        <Spinner intent={Intent.PRIMARY} />
      }
    </div>
  )
}

export default CourseReviewBlock;
