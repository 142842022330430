import RatingBlock from '../RatingBlock';
import { useTranslation } from 'react-i18next';


function CourseCardBlock({ course }) {
  const { t } = useTranslation();

  return (
    <div className="course-card course-card-list">
      <div className="course-card-thumbnail">
        <img alt="" src={course.cover_card} />
        <a href={"/course/" + course.slug + "/"} className="play-button-trigger"> </a>
      </div>
      <div className="course-card-body">
        <a href={"/course/" + course.slug + "/"}>
          <h4>{course.title}</h4>
        </a>
        <p>{course.description}</p>
        <div className="course-details-info">
          <ul>
            <li> <i className="icon-feather-sliders"></i>{t("level." + course.level)}</li>
            <li> {t("home_view.by")} <a href={"/user/" + course.author.id + "/"}>{course.author.full_name || course.author.username}</a> </li>
            <li>
              <RatingBlock rating={course.rating} />
            </li>
          </ul>
        </div>

      </div>
    </div>
  );
}

export default CourseCardBlock;
