import { nightModeSelector, setNightMode, store } from '../store';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';


function NightModeButton() {
  const nightMode = useSelector(nightModeSelector);
  const { t } = useTranslation();

  const handleSetNightMode = (e) => {
    e.preventDefault();
    store.dispatch(setNightMode(!nightMode));
  };

  return (
    <a href="/" id="night-mode" onClick={handleSetNightMode} className="btn-night-mode">
      <i className="icon-feather-moon"></i>{t("menu.night_mode")}
      <span className="btn-night-mode-switch">
        <span className="uk-switch-button"></span>
      </span>
    </a>
  )
}

export default NightModeButton;
