import { authProvider } from '../../auth';
import { userSelector } from '../../store';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import NightModeButton from '../../Components/NightModeButton';
import UserAvatar from '../../Components/UserAvatar';

function MyMenu() {
  const user = useSelector(userSelector);

  const { t } = useTranslation();

  const handleLogout = (e) => {
    e.preventDefault();
    authProvider.logout();
  };

  return (
    <div uk-dropdown="pos: top-right ;mode:click" className="dropdown-notifications small">
      <a href="/account/">
        <div className="dropdown-user-details">
          <div className="dropdown-user-avatar">
            <UserAvatar user={user} />
          </div>
          <div className="dropdown-user-name">
            {user.full_name || user.username}
            <span>{t("user_types." + user.profile.user_type.toLowerCase())}</span>
          </div>
        </div>
      </a>

      <ul className="dropdown-user-menu">
        <li>
          <a href="/"><i className="icon-material-outline-dashboard"></i>{t("menu.dashboard")}</a>
        </li>
        <li><a href="/account"><i className="icon-feather-settings"></i>{t("menu.settings")}</a>
        </li>
        <li>
          <NightModeButton />
        </li>
        <li className="menu-divider" />
        <li><a href="page-login.html" onClick={handleLogout}>
          <i className="icon-feather-log-out"></i>{t("buttons.sign_out")}</a>
        </li>
      </ul>
    </div>
  )
}

export default MyMenu;
