import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";

import HeaderBlock from '../Blocks/HeaderBlock';
import FooterBlock from '../Blocks/FooterBlock';
import { useSelector } from 'react-redux'
import { nightModeSelector } from '../store';

import HomePage from '../Pages/HomePage';
import LoginPage from '../Pages/LoginPage';
import AccountPage from '../Pages/AccountPage';
import ContactPage from '../Pages/ContactPage';
import RegisterPage from '../Pages/RegisterPage';
import TermsPage from '../Pages/TermsPage';
import PrivacyPage from '../Pages/PrivacyPage';
import CookiePolicyPage from '../Pages/CookiePolicyPage';
import CourseView from '../Pages/CourseView';
import UserView from '../Pages/UserView';
import LessonPartPage from '../Pages/LessonPartPage';
import FeedbackPage from '../Pages/FeedbackPage';
import Page404 from '../Pages/Page404';
import { useTranslation } from 'react-i18next';

import { PrivateRoute } from '../auth';


function Layout() {
  const nightMode = useSelector(nightModeSelector);
  const { t } = useTranslation();

  return (
    <BrowserRouter forceRefresh={true}>
      <Switch>
        <Route path="/course/:id/:le_id/:lp_id/">
          <LessonPartPage />
        </Route>
        <Route path="*">

          <div id="wrapper" className={nightMode ? "night-mode" : ""}>
            <HeaderBlock />

            <div className="nav-overlay uk-navbar-left uk-position-relative uk-flex-1 bg-grey uk-light p-2" hidden style={{ zIndex: '10000' }}>
              <div className="uk-navbar-item uk-width-expand" style={{ minHeight: '60px' }}>
                <form className="uk-search uk-search-navbar uk-width-1-1">
                  <input className="uk-search-input" type="search" placeholder={t("menu.search")} autoFocus />
                </form>
              </div>
              <a className="uk-navbar-toggle" uk-close="uk-close" uk-toggle="target: .nav-overlay; animation: uk-animation-fade" href="/"> </a>
            </div>

            <Switch>
              <PrivateRoute path="/account">
                <AccountPage />
              </PrivateRoute>
              <Route path="/login">
                <LoginPage />
              </Route>
              <Route path="/register">
                <RegisterPage />
              </Route>
              <Route path="/contact">
                <ContactPage />
              </Route>
              <Route path="/feedback">
                <FeedbackPage />
              </Route>
              <Route path="/terms">
                <TermsPage />
              </Route>
              <Route path="/privacy">
                <PrivacyPage />
              </Route>
              <Route path="/cookie-policy">
                <CookiePolicyPage />
              </Route>
              <Route path="/course/:id">
                <CourseView />
              </Route>
              <Route path="/user/:id">
                <UserView />
              </Route>
              <Route exact path="/">
                <HomePage />
              </Route>
              <Route path="*">
                <Page404 />
              </Route>
            </Switch>

            <FooterBlock />
          </div>
        </Route>
      </Switch>


    </BrowserRouter>
  )
}


export default Layout;
